<template>
  <div class="product">
    <loading v-if="loading"/>
    <template v-else>
      <bottom-bar :items="navs" nav/>
      <qiyu hidden ref="qiyu"/>

      <section class="hero">
        <b-row>
          <b-col class="mb-3 mb-sm-0" sm="8">
            <swiper :options="swiperOptions" class="top mb-2" ref="swiperTop">
              <swiper-slide :key="pic" v-for="pic in product.carousel">
                <square :src="pic" height="75%" img></square>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-next " slot="button-next"></div>
              <div class="swiper-button-prev " slot="button-prev"></div>
            </swiper>
          </b-col>

          <b-col class="info" sm="4">
            <h2>
              <book-icon :value="product.tid" colorful></book-icon>
              心书 · {{product.title}}
            </h2>
            <p>{{product.desc}}</p>

            <template v-if="isSmallScreen">
              <h4 class="mb-3" v-if="product.price">
                <span class="text-primary">{{product.price}}</span>
              </h4>
              <template v-else>
                <h4 class="mb-3">
                  <span class="text-primary">{{$rmb(basePrice + 20 * pagePrice)}}</span>
                  <small>起</small>
                </h4>
                <cell-link @click="selecting = !selecting" class="mb-3 border-top">
                  <span class="text-primary">
                    <fa icon="usd-square" fw/>
                    查看价格详情
                  </span>
                  <fa fw :icon="'chevron-' + (selecting ? 'up' : 'down')" slot="tail"/>
                </cell-link>
              </template>
            </template>

            <div :class="{active: selecting}" class="product-detail"
                 v-if="!isSmallScreen || selecting">
              <div @click="selecting = false" class="dimmer"></div>
              <b-card class="mb-0">
                <p class="text-muted"
                   v-if="product.specifications && product.specifications.length">
                  点击下方选项以查看不同组合的价格</p>
                <div class="specifications">
                  <b-form-group :key="item.key" v-for="item in product.specifications">
                    <template slot="label">
                      <b>{{item.title}}</b>
                      <div class="small desc text-muted float-right" v-if="item.desc">
                        {{item.desc}}
                      </div>
                    </template>
                    <select-group :options="getChoices(item.choices)"
                                  :cols="item.cols || 2"
                                  v-model="selected[item.key]"/>
                  </b-form-group>
                </div>

                <b-form-group label="价格" v-if="product.price">
                  <h4 class="mb-0">
                    <span class="text-primary">{{product.price}}</span>
                  </h4>
                </b-form-group>

                <b-form-group class="price mb-0" label="价格" v-else-if="pagePrice">
                  <h4 class="mb-0">
                    <span class="text-primary">{{$rmb(pagePrice)}} / 页</span>
                    <span style="font-size: 1rem">（基础价格 {{$rmb(basePrice)}}）</span>
                  </h4>
                  <div class="small my-2 text-muted">
                    若制作一本
                    <b>{{product.minPages}}</b>
                    页的{{product.title}}，需要花费
                    <b>{{$rmb(pagePrice * product.minPages + basePrice)}}</b>
                  </div>
                </b-form-group>

                <b-form-group class="price mb-0" label="价格" v-else>
                  <h4 class="mb-0">
                    <span class="text-primary">{{$rmb(basePrice)}}</span>
                  </h4>
                </b-form-group>

                <div class="small mt-2">
                  <fa icon="info-circle"/>
                  此处价格仅供参考，实际价格以下单页面为准
                </div>
              </b-card>
            </div>

            <div class="text-muted my-3">
              今日下单，预计{{$day().add(product.produceTime || 7, 'days').format('M月D日')}}发货。
              遇到问题？
              <b-link @click="contact">
                <fa fw icon="headset"/>
                联系客服
              </b-link>
            </div>

            <div class="d-none d-sm-block mt-3" v-if="product.tid === 'xsalbum'">
              <b-row>
                <b-col>
                  <b-btn to="/packages" block variant="primary">购买套装</b-btn>
                </b-col>
              </b-row>
            </div>

            <div class="d-none d-sm-block mt-3" v-else>
              <b-btn @click="start" block variant="primary">开始制作</b-btn>
            </div>
          </b-col>
        </b-row>
      </section>

      <section :data-title="section.title" :key="section.title" v-for="section in product.details">
        <header v-if="!section.hideTitle">
          <h1 v-image-text>{{section.title}}</h1>
          <div class="subtitle" v-html="section.subtitle" v-if="section.subtitle"></div>
        </header>
        <div class="container">
          <component :is="section.is" v-bind="section.props" v-if="section.type === 'component'"/>
          <swiper :options="section.swiperOptions" v-else-if="section.type === 'swiper'">
            <swiper-slide :key="slide.title" v-for="slide in section.swiperSlides">
              <b-card no-body>
                <square :src="slide.pic" height="70%"></square>
                <b-card-body style="min-width: 10rem;" v-if="slide.title || slide.content">
                  <h5 v-if="slide.title">{{slide.title}}</h5>
                  <div v-html="slide.content" v-if="slide.content"></div>
                </b-card-body>
              </b-card>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination" style="position: relative;"
                 v-if="section.swiperOptions.pagination"></div>
            <template v-if="section.swiperOptions.navigation">
              <div class="swiper-button-next " slot="button-next"></div>
              <div class="swiper-button-prev " slot="button-prev"></div>
            </template>
          </swiper>
          <template v-else-if="section.type === 'cards'">
            <b-row>
              <b-col :key="item.title" sm v-for="item in section.cards">
                <b-card
                  :class="['flex-sm-' + (section.layout || 'column'), {'text-sm-center': section.layout !== 'row'}]"
                  class="flex-row align-items-center" no-body>
                  <square :src="item.pic" height="70%"></square>
                  <b-card-body
                    :style="isSmallScreen ? {width: '100%', 'max-width': '50%'} : {width: '50%'}"
                    v-if="item.title || item.content">
                    <h5 v-if="item.title">{{item.title}}</h5>
                    <div v-html="item.content" v-if="item.content"></div>
                    <div class="mt-2" v-if="item.link">
                      <b-link :to="item.link">了解详情 ></b-link>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </template>
          <template v-else-if="section.type === 'image'">
            <div class="mb-3" style="border-radius: 8px;" v-for="(image, i) in section.pics"
                 :key="'pic' + i">
              <b-link :to="image.link" v-if="image.link && image.pic">
                <square :src="image.pic" class="pic" height="fit"/>
              </b-link>
              <square :src="image.pic || image" class="pic" height="fit" v-else/>
            </div>
          </template>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import { chain, forEach, transform } from 'lodash'
import route from '@/mixins/route-data'

export default {
  name: 'product',
  title() {
    return this.product.title + '产品详情'
  },
  components: {
    Qiyu: require('@/components/Qiyu').default,
    TheirWords: require('@/components/TheirWords').default
  },
  mixins: [route('albumProducts'), require('@/mixins/swiper').default],
  data() {
    return {
      selecting: false,
      swiperOptions: {
        autoplay: {
          delay: 5000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      selected: {}
    }
  },
  created() {
    this.init()
  },
  watch: {
    $route: 'init',
    selected: {
      handler(selected) {
        forEach(selected, (val, key) => {
          const section = this.product.specifications.find(s => s.key === key)
          const choices = this.getChoices(section.choices)
          if (!choices.filter(c => c.value === val).length) {
            selected[key] = choices[0].value
          }
        })
      },
      deep: true
    }
  },
  computed: {
    navs() {
      return [
        {text: '联系客服', icon: 'headset', maxWidth: '6rem', divide: true, onClick: this.contact},
        {text: '送好友 / 导私密', icon: 'gift', to: '/assistant', show: !!this.product.assistant},
        {text: '开始制作', icon: 'book', onClick: this.start, variant: 'primary'}
      ]
    },
    product() {
      const suffix = this.isSmallScreen ? '-sm' : ''

      const wxbook = {
        tid: 'wxbook',
        title: '微信书',
        desc: '朋友圈5分钟自动成书 | 2种尺寸 | 6种版式 | 3种装帧 | 海量封面自定义 | 高清印刷',
        basePrice: 18,
        pagePrice: 1,
        produceTime: 5,
        minPages: 60,
        assistant: true,
        createLink: '/create/wxbook',
        carousel: [
          require('../assets/images/detail/wxbook/swiper-6.jpg'),
          require('../assets/images/detail/wxbook/swiper-5.jpg'),

          require('../assets/images/detail/wxbook/swiper-1.jpg'),
          require('../assets/images/detail/wxbook/swiper-2.jpg'),
          require('../assets/images/detail/wxbook/swiper-3.jpg'),
          require('../assets/images/detail/wxbook/swiper-4.jpg')
        ],
        specifications: [
          {
            key: 'size',
            title: '尺寸（厘米）',
            choices: [
              {
                title: 'A5 (14.8 × 21)',
                value: 'A5'
              }, {
                title: 'A4 (21 × 28.5)',
                value: 'A4'
              }
            ]
          }, {
            key: 'binding',
            title: '装订方式',
            cols: 3,
            choices: [
              {
                title: '经济装',
                value: 'JJ',
                preview: require('../assets/images/detail/wxbook/binding-1-sm.jpg'),
                multiplier: 0.6,
                restriction: {size: 'A5'}
              }, {
                title: '文艺装',
                value: 'WY',
                preview: require('../assets/images/detail/wxbook/binding-2-sm.jpg'),
                multiplier: 1,
                restriction: {size: 'A5'}
              }, {
                title: '精装',
                value: 'JZ',
                preview: require('../assets/images/detail/wxbook/binding-3-sm.jpg'),
                multiplier: 1.5,
                addition: 38 - 18,
                restriction: {size: 'A5'}
              }, {
                title: '软装',
                value: 'RZ',
                preview: require('../assets/images/detail/wxbook/binding-4.jpg'),
                multiplier: 1.2,
                addition: 36 - 18,
                restriction: {size: 'A4'}
              }, {
                title: '精装',
                value: 'JZA4',
                preview: require('../assets/images/detail/wxbook/binding-3-a4-sm.jpg'),
                multiplier: 2,
                addition: 46 - 18,
                restriction: {size: 'A4'}
              }
            ]
          }
        ],
        details: [
          {
            title: '用户评价',
            subtitle: '「这是一份比任何东西都让人期待和惊喜的礼物！」',
            type: 'component',
            is: 'theirWords'
          },
          {
            title: '5 分钟，你的朋友圈一键成书',
            subtitle: '只需两步，朋友圈内容即可印刷成书',
            type: 'image',
            pics: [require('../assets/images/detail/wxbook/guide' + suffix + '.jpg')]
          },
          {
            title: '送好友 / 导私密',
            subtitle: '为好友制作作品，补全小编无法导出的内容',
            type: 'cards',
            layout: 'row',
            cards: [
              {
                title: '为好友做书',
                content: '给好友一个 Surp­rise，送 TA 专属作品',
                pic: require('../assets/images/detail/wxbook/gift.jpg'),
                link: '/assistant'
              },
              {
                title: '导出私密内容',
                pic: require('../assets/images/detail/wxbook/private.jpg'),
                content: '送自己一本完整的微信书，私密、分组全收录',
                link: '/assistant'
              }
            ]
          },
          {
            title: '版式设计',
            subtitle: '我们为您准备了 5 种版式，不管哪一种排版，记录时光都是我们不变的初心',
            type: 'swiper',
            swiperOptions: {
              spaceBetween: 10,
              pagination: {
                el: '.swiper-pagination'
              },
              slidesPerView: this.isSmallScreen ? 1 : 3
            },
            swiperSlides: [
              {
                title: 'A4 杂志版',
                content: '照片更大，放大你的美',
                pic: require('../assets/images/detail/wxbook/A4.jpg')
              }, {
                title: 'A4 瀑布流',
                content: '细节更多，图文排版堪比写真',
                pic: require('../assets/images/detail/wxbook/A4-1.jpg')
              }, {
                title: 'A5 瀑布流',
                content: '图文环绕，排版有呼吸',
                pic: require('../assets/images/detail/wxbook/A5-1.jpg')
              }, {
                title: 'A5 双栏',
                content: '照片定宽，排版紧凑',
                pic: require('../assets/images/detail/wxbook/A5-2.jpg')
              }, {
                title: 'A5 拾光',
                content: '单独分页，灵活错落',
                pic: require('../assets/images/detail/wxbook/A5-4.jpg')
              }
            ]
          },
          {
            title: '自定义封面 / 书名 / 内容',
            subtitle: '封面、书名自定义、朋友圈内容随心更改。<br>' +
              '平凡生活的记录、宝宝的成长日记、旅游志……这本书都可成为一个载体，承载你的点点滴滴。',
            type: 'image',
            pics: [require('../assets/images/detail/wxbook/custom' + suffix + '.jpg')]
          },
          {
            title: '海量封面自主选择',
            subtitle: '我们提供了上百款封面供您选择，您也可以自行设计上传封面，制作自己的专属作品',
            type: 'image',
            pics: [require('../assets/images/detail/wxbook/covers' + suffix + '.jpg')]
          },
          {
            title: '序言致谢',
            subtitle: '添加序言致谢，记录心路历程',
            type: 'cards',
            cards: [
              {
                pic: require('../assets/images/detail/wxbook/preface.jpg')
              },
              {
                pic: require('../assets/images/detail/wxbook/acknowledgement.jpg')
              }
            ]
          },
          {
            title: '工艺材质',
            subtitle: '我们提供三种不同的工艺包装，每一种都满怀匠心',
            type: 'image',
            pics: [
              require('../assets/images/detail/wxbook/binding-3' + suffix + '.jpg'),
              require('../assets/images/detail/wxbook/binding-2' + suffix + '.jpg'),
              require('../assets/images/detail/wxbook/binding-1' + suffix + '.jpg')
            ]
          },
          {
            title: '价格',
            subtitle: '价格 = 基础价格 + 内页单价 × 页数',
            type: 'image',
            pics: [require('../assets/images/detail/price' + suffix + '.jpg')]
          },
          {
            title: '发货',
            subtitle: '为了一份精致的时光，请给我们7个工作日打磨。<br>如遇订单高峰期，各道工序可能需等待，敬请谅解。',
            type: 'image',
            pics: [require('../assets/images/detail/delivery' + suffix + '.jpg')]
          }
        ]
      }
      const blogbook = {
        tid: 'blogbook',
        title: '长文集',
        desc: '社交长文5分钟自动成书 | 1种尺寸 | 1种版式 | 3种装帧 | 海量封面自定义 | 高清印刷',
        basePrice: 18,
        pagePrice: 1,
        produceTime: 5,
        minPages: 60,
        assistant: true,
        createLink: '/create/blogbook/xinshu',
        carousel: [
          require('../assets/images/detail/blogbook/swiper-1.jpg'),
          require('../assets/images/detail/blogbook/swiper-2.jpg'),
          require('../assets/images/detail/blogbook/swiper-3.jpg')
        ],
        specifications: [
          {
            key: 'size',
            title: '尺寸（厘米）',
            choices: [
              {
                title: 'A5 (14.8 × 21)',
                value: 'A5'
              }, {
                title: 'A4 (21 × 28.5)',
                value: 'A4'
              }
            ]
          }, {
            key: 'binding',
            title: '装订方式',
            cols: 3,
            choices: [
              {
                title: '经济装',
                value: 'JJ',
                preview: require('../assets/images/detail/wxbook/binding-1-sm.jpg'),
                multiplier: 0.6,
                restriction: {size: 'A5'}
              }, {
                title: '文艺装',
                value: 'WY',
                preview: require('../assets/images/detail/wxbook/binding-2-sm.jpg'),
                multiplier: 1,
                restriction: {size: 'A5'}
              }, {
                title: '精装',
                value: 'JZ',
                preview: require('../assets/images/detail/wxbook/binding-3-sm.jpg'),
                multiplier: 1.5,
                addition: 38 - 18,
                restriction: {size: 'A5'}
              }, {
                title: '软装',
                value: 'RZ',
                preview: require('../assets/images/detail/wxbook/binding-4.jpg'),
                multiplier: 1.2,
                addition: 36 - 18,
                restriction: {size: 'A4'}
              }, {
                title: '精装',
                value: 'JZA4',
                preview: require('../assets/images/detail/wxbook/binding-3-a4-sm.jpg'),
                multiplier: 2,
                addition: 46 - 18,
                restriction: {size: 'A4'}
              }
            ]
          }
        ],
        details: [
          {
            title: '用户评价',
            subtitle: '「这是一份比任何东西都让人期待和惊喜的礼物！」',
            type: 'component',
            is: 'theirWords'
          },
          {
            title: '5 分钟，你的社交长文一键成书',
            subtitle: '',
            type: 'image',
            pics: [require('../assets/images/detail/blogbook/guide' + suffix + '.jpg')]
          },
          {
            title: '自由创作',
            subtitle: '每个人可以自由创建多本书，不同的书里做不同的记录。这本用来记录恋爱史，那本用来写游记；<br>' +
              '这本用来记录宝宝成长，那本用来写婚姻的感悟。相互独立又各不冲突。<br>' +
              '也可以对导入的社交内容二次编辑，丰富内容或者重新排序。<br>' +
              '像发朋友圈一样的编辑界面，简单方便。',
            type: 'image',
            pics: [require('../assets/images/detail/blogbook/create' + suffix + '.jpg')]
          },
          {
            title: '自定义封面 / 书名 / 内容',
            subtitle: '封面、书名自定义、朋友圈内容随心更改。<br>' +
              '平凡生活的记录、宝宝的成长日记、旅游志……这本书都可成为一个载体，承载你的点点滴滴。',
            type: 'image',
            pics: [require('../assets/images/detail/blogbook/custom' + suffix + '.jpg')]
          },
          {
            title: '海量封面自主选择',
            subtitle: '',
            type: 'image',
            pics: [require('../assets/images/detail/blogbook/covers' + suffix + '.jpg')]
          },
          {
            title: '目录和内容',
            subtitle: '',
            type: 'cards',
            cards: [
              {
                title: '目录',
                content: '简洁清晰，方便查看内容',
                pic: require('../assets/images/detail/blogbook/catalog.jpg')
              },
              {
                title: '内容',
                content: '图文结合，有序排版',
                pic: require('../assets/images/detail/blogbook/content.jpg')
              }
            ]
          },
          {
            title: '工艺材质',
            subtitle: '我们提供三种不同的工艺包装，每一种都满怀匠心',
            type: 'image',
            pics: [
              require('../assets/images/detail/blogbook/binding-1' + suffix + '.jpg'),
              require('../assets/images/detail/blogbook/binding-2' + suffix + '.jpg'),
              require('../assets/images/detail/blogbook/binding-3' + suffix + '.jpg')
            ]
          },
          {
            title: '价格',
            subtitle: '价格 = 封面价格 + 内页单价 × 页数',
            type: 'image',
            pics: [require('../assets/images/detail/blogbook/price' + suffix + '.jpg')]
          },
          {
            title: '发货',
            subtitle: '为了一份精致的时光，请给我们7个工作日打磨。<br>如遇订单高峰期，各道工序可能需等待，延长发货时间，请各位谅解。',
            type: 'image',
            pics: [require('../assets/images/detail/blogbook/delivery' + suffix + '.jpg')]
          }
        ]
      }
      const wbbook = {
        tid: 'wbbook',
        title: '微博书',
        desc: '微博5分钟自动成书 | 2种尺寸 | 5种版式 | 3种装帧 | 海量封面自定义 | 高清印刷',
        basePrice: 18,
        pagePrice: 1,
        produceTime: 5,
        minPages: 60,
        createLink: '/create/wbbook',
        carousel: [
          require('../assets/images/detail/wbbook/swiper-1.jpg'),
          require('../assets/images/detail/wbbook/swiper-2.jpg'),
          require('../assets/images/detail/wbbook/swiper-3.jpg'),
          require('../assets/images/detail/wbbook/swiper-4.jpg'),
          require('../assets/images/detail/wbbook/swiper-5.jpg')
        ],
        specifications: wxbook.specifications,
        details: [
          {
            title: '用户评价',
            subtitle: '「这是一份比任何东西都让人期待和惊喜的礼物！」',
            type: 'component',
            is: 'theirWords'
          },
          {
            title: '5 分钟，微博内容一键成书',
            subtitle: '只需两步，微博内容即可印刷成书',
            type: 'image',
            pics: [require('../assets/images/detail/wbbook/guide' + suffix + '.jpg')]
          },
          {
            title: '送好友 / 做明星微博书',
            subtitle: '为好友制作作品，补全小编无法导出的内容',
            type: 'cards',
            layout: 'row',
            cards: [
              {
                title: '为好友做书',
                content: '给好友一个 Surp­rise',
                pic: require('../assets/images/detail/wxbook/gift.jpg'),
                link: '/create/wbbook'
              },
              {
                title: '做明星微博书',
                pic: require('../assets/images/detail/wbbook/idol.jpg'),
                content: '为爱豆应援',
                link: '/create/wbbook'
              }
            ]
          },
          {
            title: '版式设计',
            subtitle: '我们为您准备了 5 种版式，不管哪一种排版，记录时光都是我们不变的初心',
            type: 'swiper',
            swiperOptions: {
              spaceBetween: 10,
              pagination: {
                el: '.swiper-pagination'
              },
              slidesPerView: this.isSmallScreen ? 1 : 3
            },
            swiperSlides: [
              {
                title: 'A4 杂志版',
                content: '照片更大，放大你的美',
                pic: require('../assets/images/detail/wbbook/A4.jpg')
              }, {
                title: 'A4 瀑布流',
                content: '细节更多，图文排版堪比写真',
                pic: require('../assets/images/detail/wbbook/A4-1.jpg')
              }, {
                title: 'A5 瀑布流',
                content: '图文环绕，排版有呼吸',
                pic: require('../assets/images/detail/wbbook/A5-1.jpg')
              }, {
                title: 'A5 双栏',
                content: '照片定宽，排版紧凑',
                pic: require('../assets/images/detail/wbbook/A5-2.jpg')
              }, {
                title: 'A5 拾光',
                content: '单独分页，灵活错落',
                pic: require('../assets/images/detail/wbbook/A5-4.jpg')
              }
            ]
          },
          {
            title: '自定义封面 / 书名 / 内容',
            subtitle: '封面、书名自定义、微博内容随心更改。<br>' +
              '平凡生活的记录、宝宝的成长日记、旅游志……这本书都可成为一个载体，承载你的点点滴滴。',
            type: 'image',
            pics: [require('../assets/images/detail/wbbook/custom' + suffix + '.jpg')]
          },
          {
            title: '海量封面自主选择',
            subtitle: '我们提供了上百款封面供您选择，您也可以自行设计上传封面，制作自己的专属作品',
            type: 'image',
            pics: [require('../assets/images/detail/wbbook/covers' + suffix + '.jpg')]
          },
          {
            title: '序言致谢',
            subtitle: '添加序言致谢，记录心路历程',
            type: 'cards',
            cards: [
              {
                pic: require('../assets/images/detail/wxbook/preface.jpg')
              },
              {
                pic: require('../assets/images/detail/wxbook/acknowledgement.jpg')
              }
            ]
          },
          {
            title: '工艺材质',
            subtitle: '我们提供三种不同的工艺包装，每一种都满怀匠心',
            type: 'image',
            pics: [
              require('../assets/images/detail/wbbook/binding-3' + suffix + '.jpg'),
              require('../assets/images/detail/wbbook/binding-2' + suffix + '.jpg'),
              require('../assets/images/detail/wbbook/binding-1' + suffix + '.jpg')
            ]
          },
          {
            title: '价格',
            subtitle: '价格 = 基础价格 + 内页单价 × 页数',
            type: 'image',
            pics: [require('../assets/images/detail/price' + suffix + '.jpg')]
          },
          {
            title: '发货',
            subtitle: '为了一份精致的时光，请给我们7个工作日打磨。<br>如遇订单高峰期，各道工序可能需等待，敬请谅解。',
            type: 'image',
            pics: [require('../assets/images/detail/delivery' + suffix + '.jpg')]
          }
        ]
      }
      const diary = {
        tid: 'diary',
        title: '日记书',
        desc: '2种尺寸 | 5种版式 | 3种装帧 | 海量封面自定义 | 高清印刷',
        basePrice: 18,
        pagePrice: 1,
        produceTime: 5,
        minPages: 60,
        createLink: '/create/diarybook',
        carousel: [
          require('../assets/images/detail/diary/swiper-1.jpg'),
          require('../assets/images/detail/diary/swiper-2.jpg'),
          require('../assets/images/detail/diary/swiper-3.jpg'),
          require('../assets/images/detail/diary/swiper-4.jpg'),
          require('../assets/images/detail/diary/swiper-5.jpg')
        ],
        specifications: wxbook.specifications,
        details: [
          {
            title: '用户评价',
            subtitle: '「这是一份比任何东西都让人期待和惊喜的礼物！」',
            type: 'component',
            is: 'theirWords'
          },
          {
            title: '5 分钟，时光记录一键成书',
            subtitle: '只需两步，即可印刷成书',
            type: 'image',
            pics: [require('../assets/images/detail/diary/guide' + suffix + '.jpg')]
          },
          {
            title: '版式设计',
            subtitle: '我们为您准备了 5 种版式，不管哪一种排版，记录时光都是我们不变的初心',
            type: 'swiper',
            swiperOptions: {
              spaceBetween: 10,
              pagination: {
                el: '.swiper-pagination'
              },
              slidesPerView: this.isSmallScreen ? 1 : 3
            },
            swiperSlides: [
              {
                title: 'A4 杂志版',
                content: '照片更大，放大你的美',
                pic: require('../assets/images/detail/diary/A4.jpg')
              }, {
                title: 'A4 瀑布流',
                content: '细节更多，图文排版堪比写真',
                pic: require('../assets/images/detail/diary/A4-1.jpg')
              }, {
                title: 'A5 瀑布流',
                content: '图文环绕，排版有呼吸',
                pic: require('../assets/images/detail/diary/A5-1.jpg')
              }, {
                title: 'A5 双栏',
                content: '照片定宽，排版紧凑',
                pic: require('../assets/images/detail/diary/A5-2.jpg')
              }, {
                title: 'A5 拾光',
                content: '单独分页，灵活错落',
                pic: require('../assets/images/detail/diary/A5-4.jpg')
              }
            ]
          },
          {
            title: '自定义封面 / 书名 / 内容',
            subtitle: '封面、书名自定义、微博内容随心更改。<br>' +
              '平凡生活的记录、宝宝的成长日记、旅游志……这本书都可成为一个载体，承载你的点点滴滴。',
            type: 'image',
            pics: [require('../assets/images/detail/diary/custom' + suffix + '.jpg')]
          },
          {
            title: '海量封面自主选择',
            subtitle: '我们提供了上百款封面供您选择，您也可以自行设计上传封面，制作自己的专属作品',
            type: 'image',
            pics: [require('../assets/images/detail/diary/covers' + suffix + '.jpg')]
          },
          {
            title: '序言致谢',
            subtitle: '添加序言致谢，记录心路历程',
            type: 'cards',
            cards: [
              {
                pic: require('../assets/images/detail/wxbook/preface.jpg')
              },
              {
                pic: require('../assets/images/detail/wxbook/acknowledgement.jpg')
              }
            ]
          },
          {
            title: '工艺材质',
            subtitle: '我们提供三种不同的工艺包装，每一种都满怀匠心',
            type: 'image',
            pics: [
              require('../assets/images/detail/diary/binding-3' + suffix + '.jpg'),
              require('../assets/images/detail/diary/binding-2' + suffix + '.jpg'),
              require('../assets/images/detail/diary/binding-1' + suffix + '.jpg')
            ]
          },
          {
            title: '价格',
            subtitle: '价格 = 基础价格 + 内页单价 × 页数',
            type: 'image',
            pics: [require('../assets/images/detail/price' + suffix + '.jpg')]
          },
          {
            title: '发货',
            subtitle: '为了一份精致的时光，请给我们7个工作日打磨。<br>如遇订单高峰期，各道工序可能需等待，敬请谅解。',
            type: 'image',
            pics: [require('../assets/images/detail/delivery' + suffix + '.jpg')]
          }
        ]
      }

      const albumProducts = this.albumProducts.map(i => {
        i.details = i.presentations.map(item => {
          item.pic = item.pic.split('!')[0] + (window.canUseWebp ? '!webp1600' : '')
          return {
            type: 'image',
            title: item.title,
            hideTitle: true,
            pics: [item]
          }
        })
        i.createLink = '/albums?tab=products&product=' + i.tid
        return i
      })

      const products = [wxbook, blogbook, wbbook, diary, ...albumProducts]
      return products.find(i => i.tid === this.$route.params.product) || wxbook
    },
    pagePrice() {
      let price = this.product.pagePrice
      forEach(this.selected, (val, key) => {
        const choice = chain(this.product.specifications)
          .find({key})
          .get('choices')
          .find({value: val})
          .value()
        if (!choice) {
          return
        }
        price *= choice.multiplier || 1
      })
      return price
    },
    basePrice() {
      let price = this.product.basePrice
      forEach(this.selected, (val, key) => {
        const choice = chain(this.product.specifications)
          .find({key})
          .get('choices')
          .find({value: val})
          .value()
        if (!choice) {
          return
        }
        price += choice.addition || 0
      })
      return price
    }
  },
  methods: {
    init() {
      this.selected = transform(this.product.specifications, (result, val) => {
        const choices = val.choices
        result[val.key] = chain(choices).find({default: true}).get('value').value() ||
          choices[0].value
      }, {})
    },
    start() {
      this.$router.push(this.product.createLink)
    },
    contact() {
      this.$refs.qiyu.activate()
    },
    getChoices(choices) {
      return choices.filter(c => {
        if (c.restriction) {
          const keys = Object.keys(c.restriction)
          return keys.every(key => {
            return this.selected[key] === c.restriction[key]
          })
        }
        return true
      })
    }
  }
}
</script>

<style>
body[data-page="product"] {
  background: #fff;
}
</style>

<style lang="scss" scoped>
.product {
  max-width: 1280px;
}

section {
  margin-bottom: 3rem;

  header {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.hero {
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem;
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: -$grid-gutter-width / 2;
      right: -$grid-gutter-width / 2;
      border-bottom: 4px solid #f2f2f2;
    }
    .cell-link {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    .info {
      padding: 0 1rem;
    }
  }
}

.pic + .pic {
  margin-top: 1rem;
}

.product-detail {
  @include media-breakpoint-down(sm) {
    .card {
      border: 0;

      .card-body {
        padding: 0;
      }
    }
  }
}

.cashbacks {
  padding: 0;
}

.thumbs {
  .swiper-slide {
    border: 2px solid transparent;
    border-radius: 6px;
    opacity: .5;
    cursor: pointer;

    &.swiper-slide-thumb-active {
      opacity: 1;
    }
  }
}
</style>
